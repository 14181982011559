import React from 'react';
import Home1 from './home1';
import Home2 from './home2';
import classes from './homescreen.module.css';
import Home3 from './home3';
import About from './about';
import WorldStories from './Stories/worldstories';
import Helmet from '../helmet';

const Homescreen = () => {
    return (<div className={classes.main}>
        <Helmet title="Falano News" description="Top media company in Nepal."
            url="https://www.falanonews.com" image="logo" />
        <Home1 />
        <WorldStories tosliceprops />
        {/* <Home2 /> */}
        <Home3 />
        <About />

    </div>);
}

export default Homescreen;