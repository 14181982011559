import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/firebase-auth';
var firebaseConfig = {
  apiKey: "AIzaSyDQLpElv7e3uIWK_3bA2-A4zZumgjK1kCE",
  authDomain: "falano-5ff7c.firebaseapp.com",
  projectId: "falano-5ff7c",
  storageBucket: "falano-5ff7c.appspot.com",
  messagingSenderId: "104747321909",
  appId: "1:104747321909:web:cdaa84698068ec3c9ebf7c",
  measurementId: "G-N6P2Z5FGGE"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

 

export default firebase;


