import React from 'react';
import classes from './contact.module.css';
import Helmet from '../helmet';
import contactsvg from '../Homescreen/images/contactus.svg';
const Contact = () => {

  
    return ( 
    <div className={classes.container}>
        <Helmet title="Apil Tripathi contact number" description="9856011452"  
            url="https://www.falanonews.com/contact"  image="logo"  />
            <img src={contactsvg} className={classes.image} alt='contact apil number' />
        <h1 className={classes.heading}> Contact Falano News</h1>
        <p className={classes.text}>You can directly call Falano News on your cell or send an email.<br />
       <span style={{fontWeight:'900'}}>Falano News Contact Number</span>: 9856011452<br />
        Email: falano@falanonews.com<br /><br />
        Or, you can visit Falano's office at Nayabazar, Pokhara. </p>
        
        </div> );
}
 
export default Contact;