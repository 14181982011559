import React from 'react';
import Helmets from 'react-helmet';
import logo from './logo.png';
const Helmet = (props) => {

    return (
    <div>
        <Helmets> 
            <title>{props.title}</title>
            <meta name="description" content={props.description}/>
            <meta name="og:url" content={props.url} />
            <meta name="og:title" content={props.title} />
            <meta name="og:description" content={props.description} />
            <meta name="og:image" content={logo} />
            <meta name="og:type" content="website" />
            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:description" content={props.description} />
            <meta name="twitter:image" content={logo} />
            <meta name="twitter:card" content="summary" />
        </Helmets>

    </div>  );
}
 
export default Helmet